import React, { useState, useEffect } from 'react';
import './App.css';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api.php';

// Définissons le composant Welcome directement dans App.js
const Welcome = ({ username, onLogout }) => {
  return (
    <div className="welcome-container">
      <h1>Bienvenue sur Motoconnect, {username} !</h1>
      <button onClick={onLogout}>Se déconnecter</button>
    </div>
  );
};

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    const savedRememberMe = localStorage.getItem('rememberMe') === 'true';
    if (savedRememberMe) {
      setUsername(savedUsername || '');
      setRememberMe(savedRememberMe);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Tentative de connexion avec:', username, password);
    
    if (rememberMe) {
      localStorage.setItem('username', username);
      localStorage.setItem('rememberMe', 'true');
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('rememberMe');
    }

    // Vérifier les identifiants
    const users = JSON.parse(localStorage.getItem('users') || '[]');
    const user = users.find(u => u.username === username && u.password === password);
    if (user) {
      setIsLoggedIn(true);
    } else {
      alert('Identifiants incorrects.');
    }
  };

  const handleCreateAccount = (e) => {
    e.preventDefault();
    const users = JSON.parse(localStorage.getItem('users') || '[]');
    if (users.some(u => u.username === username || u.email === email)) {
      alert('Un utilisateur avec ce nom ou cet email existe déjà.');
      return;
    }
    users.push({ username, password, email });
    localStorage.setItem('users', JSON.stringify(users));
    alert('Compte créé avec succès !');
    setIsCreatingAccount(false);
    setEmail('');
    setPassword('');
  };

  const handleForgotPassword = () => {
    console.log('Fonctionnalité de mot de passe oublié');
    // Ici, vous ajouteriez la logique pour gérer le mot de passe oublié
  };

  const toggleCreateAccount = () => {
    setIsCreatingAccount(!isCreatingAccount);
    setUsername('');
    setPassword('');
    setEmail('');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setPassword('');
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <Welcome username={username} onLogout={handleLogout} />
      ) : (
        <div className="login-container">
          <h1>Motoconnect</h1>
          {!isCreatingAccount ? (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Adresse e-mail ou numéro de tél."
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button type="submit">Se connecter</button>
              <div className="remember-forgot">
                <label className="remember-me">
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <span>Conserver ma connexion</span>
                </label>
                <button type="button" onClick={handleForgotPassword} className="forgot-password">Mot de passe oublié ?</button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleCreateAccount}>
              <input
                type="text"
                placeholder="Nom d'utilisateur"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder="Adresse e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button type="submit">Créer un compte</button>
            </form>
          )}
          <div className="create-account">
            <button type="button" onClick={toggleCreateAccount}>
              {isCreatingAccount ? "Retour à la connexion" : "Créer un compte"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;